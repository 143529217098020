
























































































































































































































































@import '@design';

.main {
    display: flex;
    flex: 1;
    padding-bottom: 30px;
}

.menu {
    margin-top: 7px;
    margin-left: 15px;
}

.subtext {
    display: flex;
    align-items: center;
    margin-top: 10px;
    text-transform: lowercase;
}

.messageButton {
    margin-top: 10px;
}

.table:not(:last-child) {
    margin-bottom: 20px;
}

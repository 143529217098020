






@import '@design';

.wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
}

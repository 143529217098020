




















@import '@design';

.wrapper {
    display: flex;
    align-items: center;
}
.dot {
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    margin-right: 0.375rem;
    border-radius: 50%;
    background: red;
    &.dotGreen {
        background: var(--color-success);
    }
    &.dotRed {
        background: var(--color-error);
    }
}

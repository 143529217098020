














































































@import '@design';

.wrapper {
    margin-top: 20px;
}
// TITLE
.title {
    margin-bottom: 5px;
    font-size: 0.75em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
// JUMPS
.jumps {
    display: flex;
    justify-content: space-between;
    background: var(--color-background-tertiary);
}
.jump {
    @include unselectable;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: 10px;

    cursor: default;
    background: transparent;
    border-radius: $border-radius-round;
    box-shadow: 0 0 0 1px white;
}
.jumpActive {
    color: black;
    background: white;
    box-shadow: 0 0 0 0 rgba(white, 0.5);
    animation: pulse 1.5s ease-out forwards infinite;
    &.jumpStatusBad {
        box-shadow: 0 0 0 0 var(--color-red-light);
    }
    &.jumpStatusGood {
        box-shadow: 0 0 0 0 var(--color-green-light);
    }
}
.jumpStatusGood {
    color: black;
    background: var(--color-green);
    box-shadow: 0 0 0 5px var(--color-green-lighter);
}
.jumpStatusBad {
    color: black;
    background: var(--color-red);
    box-shadow: 0 0 0 5px var(--color-red-lighter);
}

@keyframes pulse {
    to {
        box-shadow: 0 0 0 15px rgba(white, 0);
    }
}
.jumpName {
    font-family: var(--font-family)-heading;
    font-size: 0.7em;
    font-weight: 900;
}
.status {
    position: absolute;
    bottom: -8px;
    display: flex;
}
.statu {
    width: 4px;
    height: 4px;
    margin: 1px;
    background: var(--color-green);
    border-radius: $border-radius-round;
}
.statuBad {
    background: var(--color-red);
}

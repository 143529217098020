




















































@import '@design';

.badge {
    display: flex;
    padding: 3px 10px;
    border-radius: 5px;
    margin-top: -3px;
    margin-left: 5px;

    circle {
        stroke: var(--color-secondary);
        fill: var(--color-secondary);
        stroke-width: 2px;
        stroke-opacity: 1;
    }

    .pulse {
        fill: var(--color-secondary);
        fill-opacity: 0;
        transform-origin: 50% 50%;
        animation-duration: 2s;
        animation-name: pulse;
        animation-iteration-count: infinite;
    }

    .text {
        margin-top: 2px;
        margin-left: 3px;
    }
}

@keyframes pulse {
    from {
        stroke-width: 3px;
        stroke-opacity: 1;
        transform: scale(0.3);
    }
    to {
        stroke-width: 0;
        stroke-opacity: 0;
        transform: scale(2);
    }
}

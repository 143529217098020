





















































@import '@design';

.wrapper {
    display: flex;
    padding-bottom: 20px;
    margin-top: 20px;
}
.points {
    font-size: 0.4em;
    font-style: italic;
    font-weight: 400;
}
// MAIN
.main {
    flex: 1;
}
.mainTitle {
    margin-bottom: 5px;
    font-size: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
.mainContent {
    font-size: 2em;
    font-weight: 800;
}
// DETAILS
.details {
    display: flex;
}
.detailsGroup {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 0 10px;
}
.detailsTitle {
    margin-bottom: 5px;
    font-size: 0.6em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.6;
}
.detailsContent {
    font-size: 1.6em;
    font-weight: 800;
}

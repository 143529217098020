


















@import '@design';

.container {
    overflow: hidden;
    background: var(--color-background-darkest);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 10;
}
.logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.logo {
    svg {
        width: 55px;
        height: auto;
        fill: var(--color-text-white);
    }
}
.poweredBy {
    color: var(--color-secondary);
    text-align: center;
    margin-bottom: 2px;
    margin-right: 5px;
    font-size: 10px;
}

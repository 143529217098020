
































@import '@design';

.headline {
    margin-top: 24px;
    margin-bottom: 46px;

    &.headlineAction {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
    &.headlineCenter {
        text-align: center;
    }
}
.titles {
    align-self: flex-start;
    .title {
        font-family: var(--font-family)-heading;
        font-size: 1.6em;
        font-weight: 500;
    }
    .subtitle {
        margin-top: 10px;
        font-size: 1.1em;
        font-weight: 100;
        color: var(--color-text-secondary);
    }
}

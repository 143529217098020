























.text {
    margin-top: 10px;
    margin-bottom: 13px;
    font-size: 0.9em;
    opacity: 0.8;
    font-weight: 400;
}

.video {
    max-width: 1080px;
    width: 100%;
    align-self: center;
}
































































































































































































































































@import '@design';

.main {
    display: flex;
    flex: 1;
    padding-bottom: 30px;
}
.goTo {
    display: flex;
    margin-top: -5px;
    margin-bottom: 5px;

    &.bothButtons {
        justify-content: space-between;
    }
    &.onlyPreviousButton {
        justify-content: flex-start;
    }
    &.onlyNextButton {
        justify-content: flex-end;
    }

    .link {
        font-size: 0.6rem;
        color: var(--color-secondary);
        background-color: var(--color-link);
        padding: 4px 7px;
        border-radius: 5px;
        .next,
        .previous {
            display: flex;
            height: 10px;

            .arrow {
                width: 5px;
                color: var(--color-secondary);

                &.arrowPrevious {
                    margin-right: 5px;
                }
                &.arrowNext {
                    margin-left: 5px;
                    transform: rotate(180deg);
                }
            }
        }

        &.left {
            margin-left: 10px;
        }

        &.right {
            margin-right: 10px;
        }
    }
}

/* Animation ghost riders */
.ghostRiders {
    position: relative;
    overflow: hidden;
}

.ghostRiders:before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    left: 150px;
    width: 30px;
    height: 100%;
    background-color: var(--color-secondary);
    animation: shiny 5s ease-in-out infinite;
}

@-webkit-keyframes shiny {
    0% {
        -webkit-transform: scale(0) rotate(45deg);
        opacity: 0;
    }
    80% {
        -webkit-transform: scale(0) rotate(45deg);
        opacity: 0.5;
    }
    81% {
        -webkit-transform: scale(4) rotate(45deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(50) rotate(45deg);
        opacity: 0;
    }
}

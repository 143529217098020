










@import '@design';

.wrapper {
    position: relative;
}
.scrollWrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}
.scroll {
    overflow: auto;
}

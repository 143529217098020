






@import '@design';

.title {
    @include font-heading;

    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 28px;
}

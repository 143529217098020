





















@import '@design';

.wrapper {
    position: relative;
    margin-top: -7px;
    visibility: inherit;
}
.buttonsWrapper {
    position: relative;
    margin-right: -3px;
    margin-left: -3px;
    overflow-x: scroll;
    overflow-y: hidden;
    user-select: none;
    -webkit-overflow-scrolling: touch;
    // Firefox hack
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    font-weight: 600;
}
.buttons {
    display: inline-flex;
    height: 40px;
    overflow: hidden;
    white-space: nowrap;
}
.primaryButtons {
    @extend .buttons;
}
.secondaryButtons {
    @extend .buttons;
    padding: 0;
}













































@import '@design';

.tool {
    @include unselectable;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    cursor: pointer;
    opacity: 0.4;
    span {
        display: block;
        margin-top: 2px;
        font-size: 8px;
        font-weight: 600;
        color: white;
    }
    svg {
        width: 20px;
        height: 20px;
        fill: white;
    }
    &.selected,
    &:hover {
        opacity: 1;
        svg {
            fill: #57a5e0;
        }
        span {
            color: #57a5e0;
        }
    }
    &:first-child {
        margin-top: 5px;
    }
    &:last-child {
        margin-bottom: 5px;
    }
}

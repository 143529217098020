


























.canvas {
    position: relative;
    z-index: 4;
    width: 100%;
    max-height: 600px;
}
































































































































































@import '@design';

.river,
.liverpool {
    fill: var(--color-blue);
    stroke: var(--color-blue);
}
.liverpool {
    transform: translate(0, 4.5%);
}
.jump {
    transform: translate(-50%, -50%);
    transform-box: fill-box;
    transform-origin: center;
}
.selected {
    fill: var(--color-red);
    stroke: transparent;
}



























@import '@design';

.main {
    display: flex;
    flex: 1;
}





























































@import '@design';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(white, 0.1);
    border-radius: $border-radius $border-radius 0 0;
}

// TABLE
.table {
    width: 100%;
    background-color: transparent;
}

.title {
    padding: 10px;
    display: flex;
    cursor: pointer;
}

.subtitle {
    margin-top: 8px;
}

.chevron {
    width: 6px;
    margin-left: 10px;

    &.opened {
        transform: rotate(90deg);
    }

    &.closed {
        transform: rotate(-90deg);
    }
}




























.statText {
    margin-bottom: 5px;
    font-size: 0.7em;
    opacity: 0.8;
    font-weight: 400;
    font-style: italic;
}

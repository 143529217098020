















@import '@design';

.liverpool {
    fill: var(--color-blue);
    stroke: var(--color-blue);
}
.selected {
    fill: var(--color-red);
    stroke: transparent;
}








@import '@design';

.wrapper {
    display: flex;
    align-items: center;
}

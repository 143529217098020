





























































































@import '@design';

.statTitle {
    margin-bottom: 7px;
    margin-top: 10px;
    font-size: 0.9em;
    opacity: 0.8;
    font-weight: 400;
}
.listTxt {
    margin-top: 15px;
    margin-bottom: 15px;
}
.pieChart {
    margin: 20px 0 20px -30px;
}








@import '@design';

.panel {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    color: #fff;
    pointer-events: auto;
    background: rgba(51, 51, 51, 0.95);
    transition: opacity 0.1s ease-in-out, bottom 0.4s cubic-bezier(0.8, 0, 0, 1), right 0.4s cubic-bezier(0.8, 0, 0, 1),
        transform 0.4s cubic-bezier(0.8, 0, 0, 1);
}

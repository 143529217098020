






























@import '@design';

.spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 30px;
    margin: auto;
}
.spinnerEllipse {
    fill: transparent;
    stroke: var(--color-text);
    stroke-dasharray: 60;
    stroke-dashoffset: 20;
    stroke-linecap: round;
    stroke-width: 1.8;
    transform-origin: 50% 50%;
    animation: SpinnerAnimationTail 2.55s ease-in-out infinite, SpinnerAnimationRotation 0.7s linear infinite;
}

@keyframes SpinnerAnimationTail {
    0%,
    to {
        stroke-dashoffset: 5;
    }
    35%,
    45% {
        stroke-dashoffset: 57.2;
    }
}

@keyframes SpinnerAnimationRotation {
    0% {
        transform: scaleX(-1) rotate(0);
    }
    50% {
        transform: scaleX(-1) rotate(-180deg);
    }
    to {
        transform: scaleX(-1) rotate(-1turn);
    }
}


























@import '@design';

.wrapper {
    display: flex;
    align-items: center;
}
.percent {
    margin-right: 0.5rem;
}
.progress {
    width: 100%;
    font-size: 0.75rem;
    display: flex;
    overflow: hidden;
    height: 4px;
    border-radius: $border-radius-round;
    background-color: var(--color-border);
}
.progressBar {
    height: auto;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: width 0.6s ease;
    text-align: center;
    white-space: nowrap;
    color: white;
    background-color: white;
    &.inProgress {
        background-color: var(--color-success);
    }
}

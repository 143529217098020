

















.column {
    max-width: 50%;
    flex: 0 0 50%;
}
.padding {
    padding: 20px;
}











































































































































@import '@design';

.wrapper {
    display: flex;
    justify-content: space-between;
}
// TIME
.time {
    display: flex;
}
.timeTitle {
    font-size: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
.times {
    display: flex;
}
.timeContent {
    font-size: 2em;
    font-weight: 800;
}
.timeToBeat {
    display: inline-block;
    padding: 3px;
    margin-top: 3px;
    font-size: 0.7em;
    font-weight: 800;
    color: var(--color);
    text-transform: uppercase;
    background: white;
    border-radius: 2px;
    transition: all 1s ease-out;
    span {
        font-size: 0.7em;
        opacity: 0.8;
    }
}
.timeMax {
    display: inline-block;
    padding: 3px;
    margin-top: 3px;
    font-size: 0.7em;
    font-weight: 800;
    color: black;
    text-transform: uppercase;
    background: var(--color-red);
    border-radius: 2px;
    transition: all 1s ease-out;
    span {
        font-size: 0.7em;
        opacity: 0.8;
    }

    &.moveOnRigth {
        margin-left: 8px;
    }
}
.final {
    margin-left: 10px;
    padding: 5px;
    font-size: 12px;
    border-radius: $border-radius;
    white-space: nowrap;
    align-self: center;

    &.before {
        color: var(--color-intermediate-before);
        border: 1px solid var(--color-intermediate-before);
    }
    &.after {
        color: var(--color-red);
        border: 1px solid var(--color-red);
    }
}

// RIGHT
.right {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}
.intermediates {
    display: flex;
    align-self: stretch;
    justify-content: flex-end;

    > div:not(:first-child) {
        margin-left: 5px;
    }
}
.intermediate {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    height: 30px;
    // text
    font-family: var(--font-family)-heading;
    font-size: 1em;
    font-weight: 900;
    color: var(--color-secondary);
    &:not(:first-child) {
        margin-left: 3px;
    }
    color: var(--color-background-darkest);

    &.before {
        background-color: var(--color-intermediate-before);
    }
    &.after {
        background-color: var(--color-red);
    }
}
.intermediateLabel {
    margin-bottom: 3px;
}
.speed {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    height: 20px;
    line-height: 20px;
}
.speeds {
    margin-left: 10px;
}



















































































































































@import '@design';

.wrapper {
    @include unselectable;

    overflow: visible;
    cursor: pointer;
    &.dragging {
        cursor: none;
    }
}
.jump {
    fill: var(--color-blue);
    stroke: var(--color-blue-light);
    stroke-width: 1;
    transition: transform 100ms ease-out;
    transform: translateY(0);
}
.jumpSelected {
    fill: var(--color-red);
    stroke: var(--color-red-lighter);
}
.jumpDragged {
    transform: translateY(-3px);
}
.jumpHidden {
    opacity: 0;
}
.precisionPoint {
    fill: var(--color-red);
}
.rotateMarker {
    stroke: white;
    stroke-width: 0.2;
    transition: transform 100ms ease-out;
    &.rotateMarkerDragged {
        transform: translateY(-3px);
    }
}
.text {
    @include unselectable;

    font-size: 1.5px;
    text-anchor: middle;
    fill: white;
    transform: translate(0, 4.5%);
}








@import '@design';

.card {
    background-color: var(--color-background-tertiary); // var(--color-background-secondary)
    background-clip: border-box;
    border-radius: $border-radius;
    box-shadow: 0 0 2rem 0 rgba(black, 0.15);
}



































































@import '@design';

.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}
.overlay {
    background: rgba(black, 0.85);
}

// Transition
.enterActive,
.leaveActive {
    transition: all 0.3s ease;
}
.enter,
.leaveTo {
    opacity: 0;
}


















@import '@design';

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.empty {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    padding: 15px;
    margin: 20px;
    border: 1px solid var(--color-border);
    border-radius: $border-radius;
}
.title {
    font-weight: 600;
    text-align: center;
}
.subtext {
    margin-top: 5px;
    font-size: 0.85em;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    opacity: 0.7;
}
.buttons {
    margin-top: 10px;
}

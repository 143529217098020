












































@import '@design';

.section {
    display: flex;
    flex-direction: column;
    &:not(:first-child) {
        border-top: 1px solid rgba(white, 0.1);
    }
}
.titles {
    display: flex;
    background: #272727;
}
.title {
    position: relative;
    display: inline-flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 38px;
    padding: 0 15px;
    font-size: 9px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    opacity: 0.5;
    &.isActive {
        background: var(--color-background-secondary);
        background: linear-gradient(#373737, #313131);
        opacity: 1;
    }
}
.contentsWrapper {
    flex: 1;
}
.contents {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}

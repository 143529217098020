
































@import '@design';

.clear {
    fill: var(--color-map-jump-clear);
    fill-opacity: 80%;

    &.pulse {
        // animation: fade 3s infinite, pulse 2s infinite;
        animation: fade 2s 3;
    }
}

.fault {
    fill: var(--color-map-jump-fault);
    fill-opacity: 80%;

    &.pulse {
        animation: pulse 2s infinite;
        -webkit-filter: drop-shadow(0px 0px 1px var(--color-map-jump-fault));
        filter: drop-shadow(0px 0px 1px var(--color-map-jump-fault));
    }
}

@keyframes pulse {
    0%,
    100% {
        transform: scale(0.7);
    }

    70% {
        transform: scale(1);
    }
}

@keyframes fade {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }
}

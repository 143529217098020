



































































@import '@design';

.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;

    @include tablet {
        overflow-y: auto;
    }

    @include mobile {
        overflow-y: hidden;
        pointer-events: none;
        background: none;
        transform: translate3d(0, 0, 0);
    }
}
.overlay {
    @include tablet {
        background: rgba(black, 0.9);
    }

    @include mobile {
        display: none;
    }
}
.table {
    @include tablet {
        display: table;
        width: 100%;
        height: 100%;
    }

    @include mobile {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        pointer-events: auto;
        will-change: transform;
    }
}
.cell {
    @include overflow-touch;

    @include tablet {
        display: table-cell;
        padding: 64px;
        vertical-align: middle;
    }

    @include mobile {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        overflow-y: auto;
        background-color: var(--color-background);
    }
}
.modal {
    width: 100%;
    margin: auto;

    @include tablet {
        position: relative;
        max-width: 450px;
        background-color: var(--color-background);
        border-radius: $border-radius;
    }

    @include mobile {
        height: 100%;
        padding: 20px;
    }
}
.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    .title {
        position: absolute;
        width: 100%;
        text-align: center;
        opacity: 0.6;
    }
    .closeButton {
        position: relative;
        z-index: 5;
        display: inline-block;
        margin: 0 10px;
        cursor: pointer;
    }
    .closeCross {
        position: relative;
        display: block;
        width: 16px;
        height: 16px;
        font-size: 0;
        cursor: pointer;
        &::after,
        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 2px;
            margin: auto;
            content: '';
            background: var(--color-text);
            border-radius: 3px;
            transform: rotate(45deg);
        }
        &::after {
            transform: rotate(-45deg);
        }
    }
}
.modalBody {
    padding: 20px;
}
.modalFooter {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid var(--color-border);
}

.slideEnterActive,
.slideLeaveActive {
    transition: all 0.3s ease;
}
.slideEnter,
.slideLeaveTo {
    transform: translate3d(0, 100%, 0);
}
.opacityEnterActive,
.opacityLeaveActive {
    transition: all 0.3s ease;
}
.opacityEnter,
.opacityLeaveTo {
    opacity: 0;
}

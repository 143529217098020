

















































































@import '@design';

.wrapper {
    @include unselectable;

    overflow: visible;
    cursor: pointer;
}
.text {
    @include unselectable;

    font-size: 1.5px;
    font-weight: 400;
    text-anchor: middle;
    fill: #f0f0f0;
}
.mostKnockedJump {
    &.percent {
        font-size: 1.2px;
    }
}
.mostKnockedArea {
    fill: url(#gradient-most-knocked);
    opacity: 0.5;
}
.text-background {
    fill: var(--color-background-secondary);
    stroke: var(--color);
    stroke-width: 0.1px;
    opacity: 0.8;
}

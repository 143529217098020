







































































@import '@design';

.path {
    fill: none;
    stroke: var(--color-map-path);
    stroke-width: 0.3;
}

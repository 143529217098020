






















@import '@design';

.checkerboard {
    .white {
        fill: var(--color-text-white);
    }

    rect:first-child {
        stroke: var(--color-background-dark);
        stroke-width: 0.3;
    }
}

.animation {
    animation: shake 1s both; // cubic-bezier(0.36, 0.07, 0.19, 0.97)
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(1.5px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0);
    }
}








@import '@design';

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: #f2f3f5;
    border-radius: $border-radius-round;
    svg {
        max-width: 20px;
        height: 100%;
        max-height: 20px;
    }
}

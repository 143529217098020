




























@import '@design';

.icon {
    width: 24px;
    height: 24px;
}
.purpleColor {
    fill: var(--color-purple);
}
.redColor {
    fill: var(--color-red);
}
.orangeColor {
    fill: var(--color-orange);
}
.whiteColor {
    fill: white;
}
.greyColor {
    fill: #9aa0a6;
}

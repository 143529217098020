






.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    margin-right: auto;
    margin-left: auto;
}

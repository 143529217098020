












































































































































@import '@design';

.wrapper {
    width: 100%;
    height: 100%;
}
.path {
    fill-opacity: 0;
    stroke: var(--color-link);
    stroke-width: 1;
    &.pathShow {
        fill-opacity: 1;
        transition: all 1s ease-out;
        animation: create;
    }
}

@keyframes create {
    to {
        stroke-dashoffset: 0;
    }
}
.svg {
    width: 100%;
    height: 100%;
    .fill {
        background: white;
    }
}
.maxHeight {
    fill: var(--color-link);
    stroke: white;
    stroke-width: 0.5px;
    transition: r 200ms;
    animation: pulsation 2.5s infinite ease-in-out;
    &:hover {
        animation: pulsation 2.5s infinite ease-in-out;
    }
}

@keyframes pulsation {
    50% {
        r: 3;
        stroke-width: 0.75px;
    }
}
.maxHeightName {
    font-size: 6px;
    text-anchor: middle;
    transform: translateY(10px);
}

@keyframes born {
    0% {
        r: 0;
    }
    70% {
        r: 17;
    }
    100% {
        r: 13;
    }
}
.pitch {
    circle {
        fill: var(--color-text);
    }
    line {
        stroke: var(--color-text-lighter);
        stroke-dasharray: 1;
        stroke-width: 0.5;
    }
}

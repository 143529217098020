$box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.1);
$box-shadow-soft: 0 8px 10px -5px rgba(black, 0.04);
$box-shadow-strong: 0 4px 6px
    rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(black, 0.08);
$box-shadow-button: inset 0 5px 15px 1px
    rgba(255, 255, 255, 0.15),
  0 4px 6px rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(black, 0.08);
$box-shadow-popup: rgba(black, 0.5) 0 0 20px 20px;

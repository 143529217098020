
























@import '@design';

.startPennant path {
    stroke: black;
    stroke-width: 0.1;
}

.notStarted path {
    &:nth-child(2) {
        fill: var(--color-red);
    }
    &:last-child {
        fill: var(--color-text-white);
    }
}

.started path {
    &:nth-child(2) {
        animation: fadeFromRed 2s 1;
        animation-fill-mode: forwards;
    }
    &:last-child {
        animation: fadeFromWhite 2s 1;
        animation-fill-mode: forwards;
    }
}

@keyframes fadeFromRed {
    from {
        fill: var(--color-red);
    }
    to {
        fill: var(--color-green);
    }
}

@keyframes fadeFromWhite {
    from {
        fill: var(--color-text-white);
    }
    to {
        fill: var(--color-green);
    }
}

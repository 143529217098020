






































@import '@design';

.button {
    display: inline-flex;
    align-self: center;
    padding: 8px 12px;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: var(--button-font-weight);
}
.active {
    border-radius: $border-radius;
}

.primary {
    @extend .button;
    color: var(--color-secondary);
}
.secondary {
    @extend .button;
    color: var(--color-text-tab);
}
.activePrimary {
    @extend .active;
    color: var(--color-active);
    background: rgba(white, 0.05);
}
.activeSecondary {
    @extend .active;
    color: var(--color-active-light);
    background: var(--color-background-active);
}

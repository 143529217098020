





























@import '@design';

.wrapper {
    display: flex;
}

// MAIN
.main {
    flex: 1;
}
.mainTitle {
    font-size: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
.mainContent {
    font-size: 2em;
    font-weight: 800;
}
.hidden {
    visibility: hidden;
}

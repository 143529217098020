



































@import '@design';
.messageButton {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}
.toast {
    font-size: 20px;
    a {
        color: white;
    }
}






















.wrapper {
    padding: 10px;
    margin: 10px;
    overflow: hidden;
    border: 1px solid rgba(70, 70, 70, 0.8);
    border-radius: 6px;
}
.title {
    display: inline-block;
    padding: 5px;
    margin-bottom: 10px;
    font-size: 10px;
    font-weight: 600;
    color: #ff5678;
    background: rgba(255, 86, 120, 0.1);
    border-radius: 4px;
}

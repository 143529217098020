






.field {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    &:not(:last-child) {
        margin-right: 10px;
    }
}

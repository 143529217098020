






























































































































































@import '@design';

.wrapper {
    @include unselectable;

    display: flex;
    align-items: center;
    padding: 4px;
    margin: 0 -4px;
    border-radius: $border-radius;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}
.wrapperSelected {
    background: var(--color-background-hover);
}
.odd {
    background: var(--color-background-tertiary);
}
// Photo
.photo {
    position: relative;
    margin-left: 5px;
}
.photoImage {
    margin-right: 10px;
    margin-left: 5px;
}
.photoRank {
    position: absolute;
    bottom: 0;
    left: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    font-size: 9px;
    font-weight: 600;
    color: black;
    background: white;
    border-radius: $border-radius-round;
    box-shadow: 0 0 3px 0 black;
}

.nameHorse {
    margin-top: 3px;
    font-size: 0.85em;
    font-weight: 400;
    opacity: 0.7;
}
.country {
    position: absolute;
    bottom: 0;
    right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.qualified {
    border: solid 2px var(--color-text-dark);
    height: 100%;
    position: absolute;
    margin-left: -5px;
}

// Right
.right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}
.rightStatus {
    display: inline-flex;
    padding: 5px;
    font-size: 12px;
    color: var(--color-text-dark);
    border: 1px solid var(--color-text-dark);
    border-radius: $border-radius;
    white-space: nowrap;
}
.results {
    display: flex;
    height: 100%;
}
.resultsGroup {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 10px;
}
.resultsTitle {
    margin-bottom: 3px;
    font-size: 0.6em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
.resultsContent {
    font-size: 1.2em;
    font-weight: 800;
}
.resultTime {
    width: 70px;
}
.resultTimeLarge {
    width: 120px;
}
.resultFault {
    width: 105px;
}
.jumpFaults {
    font-size: 0.5em;
    font-weight: 400;
    color: var(--color-text-dark);
}
// Time diff
.diffWrapper {
    margin-top: 10px;
    width: 50px;
}
.timeDiff {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    // text
    font-family: var(--font-family)-heading;
    font-size: 0.7em;
    font-weight: 800;
    color: var(--color-secondary);
    &:not(:first-child) {
        margin-left: 3px;
    }
    color: var(--color-background-darkest);
    background-color: var(--color-red);
}

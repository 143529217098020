













@import '@design';

.wrapper {
    background: var(--color-background-tertiary);
    border-bottom: 1px solid var(--color-border);
}
.th {
    padding-top: 0.75rem;
    padding-right: 1.5rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    font-size: 0.65rem;
    font-weight: 600;
    text-align: inherit;
    text-transform: uppercase;
    letter-spacing: 1px;
    vertical-align: middle;
}
.head {
    display: flex;
    align-items: center;
}
.sortable svg {
    margin-left: 0.25rem;
    opacity: 0.3;
}
.sorted svg {
    opacity: 1;
    fill: var(--color-link);
}

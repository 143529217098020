









































@import '@design';

.tbody {
    td,
    th {
        padding: 1rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        font-size: 0.8125rem;
        vertical-align: middle;
        border-top: 1px solid var(--color-text);
    }
    tr:first-child {
        td,
        th {
            border-top: 0;
        }
    }
}

































@import '@design';

.wrapper {
    display: flex;
    align-items: center;
}
.image {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: $border-radius-round;
    background-color: white;
    border: 2px solid white;
}
.text {
    margin-left: 1rem;
}
.subtext {
    font-size: 0.85em;
    margin-top: 3px;
    font-weight: 400;
    opacity: 0.7;
}










































































.pointer {
    cursor: pointer;
}
.th {
    display: flex;
    color: inherit;

    th {
        width: 100%;
    }
}

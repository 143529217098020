






@import '@design';

.box {
    background: white;
    border-radius: $border-radius;
    box-shadow: $box-shadow-soft;
    & > * {
        padding: 20px;
        &:not(:last-child) {
            border-bottom: 1px solid #eee;
        }
    }
}

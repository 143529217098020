

































































@import '@design';

.list {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 3px;
    &:not(:first-child) {
        margin-top: 10px;
    }
}
.selectable {
    position: relative;
    z-index: 1px;
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 0 15px;
    margin-right: -15px;
    .selectableButton {
        width: 15px;
        height: 15px;
        border: 1px solid white;
        border-radius: $border-radius-round;
    }
}
.titles {
    flex: 1;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    .title {
        font-weight: 800;
    }
    .subtitle {
        font-size: 12px;
    }
}
.action {
    padding: 15px;
}
.noResult {
    padding: 20px;
    color: var(--color-important);
    background: var(--color-important-light);
}
.noResultTitle {
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 700;
}
.noResultSubtitle {
    font-size: 16px;
}

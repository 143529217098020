









@import '@design';

.subtext {
    margin-top: 3px;
    font-size: 0.85em;
    font-weight: 400;
    opacity: 0.7;
}































@import '@design';

.asset {
    transform: translate(-50%, -50%);
    transform-box: fill-box;
    transform-origin: center;
}
.selected * {
    fill: var(--color-red);
    stroke: transparent;
}

































































































































































































@import '@design';

.wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.listCompetitors,
.competitors {
    padding-bottom: 0;
}
.competitors {
    background: linear-gradient(var(--color-background-secondary), var(--color-background-darkest) 50%);
}
.map {
    background: var(--color-background-tertiary);
}
.claps {
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
}
.hidden {
    visibility: hidden;
}

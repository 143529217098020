











































































































@import '@design';

.wrapper {
    padding-bottom: 20px;
}

//
// COMPETITOR
//
.competitor {
    display: flex;
    align-items: center;
}
// COMPETITOR: photo
.photo,
.svg {
    width: 60px;
    height: 60px;
    border: 3px solid var(--color);
    border-radius: $border-radius-round;
}
.svg {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 26px;
        height: 26px;
        fill: var(--color-secondary-lighter);
    }
}
// COMPETITOR: names
.names {
    flex: 1;
    margin-left: 10px;
    color: var(--color-lightest-gray);
}
.name {
    font-size: 1.2em;
    font-weight: 800;
}
.nameHorse {
    margin-top: 3px;
    font-size: 0.9em;
    opacity: 0.85;
}
// COMPETITOR: details
.details {
    display: flex;
    margin-left: 15px;
}
.detail {
    padding: 6px 10px 4px 10px;
    background: var(--color-background-tertiary);
    border-radius: 2px;
    &:not(:first-child) {
        margin-left: 5px;
    }
}
.detailTitle {
    margin-bottom: 3px;
    font-size: 0.6em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.8;
}
.detailContent {
    font-size: 1.2em;
    font-weight: 800;
}
.country {
    display: flex;
}
.flag {
    margin-left: 5px;
}
.rightStatus {
    display: inline-flex;
    padding: 5px;
    font-size: 12px;
    color: var(--color);
    border: 1px solid var(--color);
    border-radius: $border-radius;
    white-space: nowrap;
    float: right;
}






































































.wrapper {
    margin-top: 10px;
}
.statTitleList {
    margin-bottom: 7px;
    font-size: 0.9em;
    opacity: 0.8;
    font-weight: 400;
}
.statText {
    margin-bottom: 15px;
    font-size: 0.9em;
    opacity: 0.8;
    font-weight: 400;
}
































































@import '@design';

.header {
    overflow: hidden;
    background: var(--color-background-secondary);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.logoWrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 65px;
}
.logoImg {
    margin: auto;
}
.logo {
    display: flex;
    justify-content: center;
}
.logoBaseline {
    margin-left: 5px;
    font-size: 18px;
    font-weight: 700;
}
.eventLogo {
    margin-left: 10px;
}
.eventTitle {
    line-height: 15px;
    font-size: smaller;
    margin: auto;
    margin-left: 10px;
    text-transform: uppercase;
    // text-align: center;
    color: var(--color-secondary);
}


































@import '@design';

.image,
.svg {
    width: 48px;
    height: 48px;
    margin-right: 1rem;
    border: 2px solid var(--color);
    border-radius: $border-radius-round;
}
.bigImage,
.bigSvg {
    width: 70px;
    height: 70px;
}
.image {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.svg {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 26px;
        height: 26px;
        fill: var(--color-background-image-light);
    }
}

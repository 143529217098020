






































































@import '@design';

.input {
    @include inputEdit;
}
.bigSize {
    height: 40px;
    font-size: 1.1em;
}
.details {
    display: inline;
    margin-top: 5px;
    font-size: 10px;
    cursor: default;
    opacity: 0.6;
    color: var(--color-error);
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin taphighlight {
  -webkit-tap-highlight-color: transparent;
}

@mixin overflow-touch {
  -webkit-overflow-scrolling: touch;
}

@mixin box {
  background: var(--color-background);
  border-radius: $border-radius;
  box-shadow: $box-shadow-soft;
}

@mixin input {
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-text);
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: $border-radius;
  border-image: initial;
  transition: border-color 0.2s ease-in-out 0s;
}

@mixin inputEdit {
  width: 100%;
  height: 32px;
  padding: 6px 8px;
  color: var(--color-text-input);
  border-radius: 4px;
  background: var(--color-background);
}

@mixin label {
  overflow: hidden;
  font-weight: 400;
  color: var(--color-text-light);
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
}

@mixin formbox {
  background: var(--color-background);
  border-radius: $border-radius;
  box-shadow: $box-shadow-soft;
}

@mixin animateText {
  opacity: 0;
  transition: opacity 1.2s
      cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 1.4s
      cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateY(25px) scale(0.8);
}

@mixin jump {
  width: 6px;
  background: var(--color-link);
  border-radius: $border-radius;
  box-shadow: 0 0 16px 0 #0000003b;
  &:not(:first-child) {
    margin-left: 3px;
  }
}

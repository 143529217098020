






















@import '@design';

.wrapper {
    display: flex;
    align-self: end;
}
.speeds {
    display: flex;
}
.speed {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    // text
    font-size: 1em;
    font-weight: 900;

    &:not(:first-child) {
        margin-left: 5px;
        padding-left: 5px;
        border-left: solid var(--color-background) 2px;
    }
}

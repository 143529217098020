










































































@import '@design';

.wrapper {
    display: table;
}
.legend {
    display: table-cell;
    margin-left: -30px;
    vertical-align: middle;

    ul {
        display: table-cell;
    }
}
.label {
    margin-top: 7px;
    margin-left: 7px;
}
.pie {
    width: 240px;

    circle {
        fill: none;
        stroke-width: 32;
    }
}
